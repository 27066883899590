import { FaqItem } from 'src/models/FaqItem';
import FetchError from './errors';
import { customFetchWithRetry } from './shared';

export const getFaqItems = async (orgId: string): Promise<FaqItem[] | null> => {
  try {
    const response = await customFetchWithRetry(`/client/guide?orgID=${orgId}`, {
      method: 'GET'
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }

    const errorText = await response.text();
    throw new FetchError(`Error fetching FAQ items. ${errorText}`, response.status);
  } catch (e: any) {
    throw new FetchError(e.message || e, 500);
  }
};
