import { Auth as Authorization } from 'aws-amplify';
import { API_BASE_URL, TEST_API_BASE_URL } from '../../constants'
import { getMaxRetriesOnErrorConfig } from '../../utils/remoteConfig'

export const getAuthHeaders = async (auth = true): Promise<Headers | Record<string, string> | string[][]> => {
    if (!auth) {
        return {
            "Content-Type": "application/json",
            "Accept": "*/*"
        }
    }
    const authToken = await getToken();

    return authToken
        ? {
            "Authorization": `Bearer ${authToken}`,
            "Content-Type": "application/json",
            "Accept": "*/*"
        }
        : {
            "Content-Type": "application/json",
            "Accept": "*/*"
        };
};

const getToken = async (): Promise<string> => {
    const session: any = await Authorization.currentSession();
    const token = session.accessToken.jwtToken
    return token
}

export const customFetchWithRetry = async (
    input: RequestInfo,
    init?: RequestInit | undefined,
    auth = true
): Promise<Response> => {
    return customFetch(input, init, auth, true, 0)
};

export const customUrlFetchWithRetry = async (
    url: string,
    init?: RequestInit | undefined,
    auth = true
): Promise<Response> => {
    return customFetchApiWithUrl(url, init, auth, true, 0)
};

export const customFetch = async (
    input: RequestInfo,
    init?: RequestInit | undefined,
    auth = true,
    retry = false,
    retryCount = 0
): Promise<Response> => {
    return customFetchApi(API_BASE_URL()!!, input, init, auth, retry, retryCount)
};

export const customTestFetch = async (
    input: RequestInfo,
    init?: RequestInit | undefined,
    retry = false,
    retryCount = 0
): Promise<Response> => {

    return customFetchApi(TEST_API_BASE_URL()!!, input, init, true, retry, retryCount)
};

const customFetchApi = async (
    baseUrl: string,
    input: RequestInfo,
    init?: RequestInit | undefined,
    auth = true,
    retry = false,
    retryCount = 0
): Promise<Response> => {
    return customFetchApiWithUrl(baseUrl + input, init, auth, retry, retryCount)
};

const customFetchApiWithUrl = async (
    url: string,
    init?: RequestInit | undefined,
    auth = true,
    retry = false,
    retryCount = 0
): Promise<Response> => {

    try {
        const response = await fetch(`${url}`, {
            ...init,
            headers: {
                ...init?.headers,
                ...await getAuthHeaders(auth),
                ...(init &&
                    (init.method === "PUT" || init.method === "POST") && {
                    "Content-Type": "application/json"
                })
            }
        });

        if (response.ok) {
            return response;
        }

        if(retry && retryCount < getMaxRetriesOnErrorConfig()) {
            return customFetchApiWithUrl(url, init, auth, retry, retryCount + 1)
        }

        return response;
    } catch (error) {
        // if the retryCount has not been exceeded, call again
        if (retry && retryCount < getMaxRetriesOnErrorConfig()) {
            return customFetchApiWithUrl(url, init, auth, retry, retryCount + 1)
        }
        // max retries exceeded
        throw error
    }
};