import { IProductRelease, IProductReleases } from "../models";
import FetchError from "./errors";
import { customFetchWithRetry } from "./shared";

export const getProductReleases = async (productId: string): Promise<IProductRelease[] | null> => {
  try {
    const response = await customFetchWithRetry(`/product/${productId}/release`, {
      method: "GET",
    }, false)
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};