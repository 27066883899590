import FetchError from "./errors";
import { IAllUserSettingsResponse, IUpdateUserSettingsRequest, IUserActivity, IUserSettings, IUserSettingsRequest, IUserUpdateLogoRequest } from "../models";
import { customFetchWithRetry } from "./shared";
import { IUserProfile } from "src/models/UserProfile";

export const logUserActivity = async (request: IUserActivity): Promise<Boolean | null> => {
  try {
    const response = await customFetchWithRetry(`/user/${request.username}/activity`, {
      method: "POST",
      body: JSON.stringify(request)
    })
    if (response.ok) {
      return true;
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};

export const getUserProfile = async (): Promise<IUserProfile | null> => {
  try {
    const response = await customFetchWithRetry(`/client/userProfile`, {
      method: "GET"
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};

export const getUserSettings = async (request: IUserSettingsRequest): Promise<IUserSettings | null> => {
  try {
    const response = await customFetchWithRetry(`/user/${request.username}/settings?orgId=${request.orgId}&grainId=${request.grainId}&profileId=${request.profileId}&settingName=${request.settingName}`, {
      method: "GET"
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};

export const updateUserSettings = async (request: IUpdateUserSettingsRequest): Promise<IUserSettings | null> => {
  try {
    const response = await customFetchWithRetry(`/user/${request.username}/settings`, {
      method: "POST",
      body: JSON.stringify(request)
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};

export const updateUserLogo = async (request: IUserUpdateLogoRequest): Promise<IUserSettings | null> => {
  try {
    const response = await customFetchWithRetry(`/user/${request.username}/settings`, {
      method: "PUT",
      body: JSON.stringify(request)
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};

export const globalLogout = async (username: string): Promise<string | null> => {
  try {
    const response = await customFetchWithRetry(`/user/logout`, {
      method: "POST",
      body: JSON.stringify({
        "username": username
      })
    }, false)
    if (response.ok) {
      return "SUCCESS";
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};