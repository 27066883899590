import FetchError from "./errors";
import { IJob, IJobRequest, IJobs, IOrgJobListRequest, IQueueRequest, IUpdateJobRequest, IUpdateJobResultRequest, IUserJobListRequest } from "../models";
import { customFetchWithRetry } from "./shared";

export const createJob = async (jobRequest: IJobRequest): Promise<Boolean | null> => {
  try {
    const response = await customFetchWithRetry(`/jobv2`, {
      method: "POST",
      body: JSON.stringify(jobRequest)
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    console.log('error', e)
    throw new FetchError(e, 500);
  }
};

// used only for test purpose
export const queueJob = async (request: IQueueRequest): Promise<Boolean | null> => {
  try {
    const response = await customFetchWithRetry(`/job/queue`, {
      method: "POST",
      body: JSON.stringify(request)
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    console.log('error', e)
    throw new FetchError(e, 500);
  }
};

export const updateJob = async (jobRequest: IUpdateJobRequest): Promise<Boolean | null> => {
  try {
    const response = await customFetchWithRetry(`/job`, {
      method: "PUT",
      body: JSON.stringify(jobRequest)
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};

export const updateJobResult = async (request: IUpdateJobResultRequest): Promise<Boolean | null> => {
  try {
    const response = await customFetchWithRetry(`/jobv2/${request.jobId}/result`, {
      method: "POST",
      body: JSON.stringify(request)
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};

export const getJobs = async (request: IUserJobListRequest): Promise<IJobs | null> => {
  try {
    var requestUrl = `/user/${request.username}/jobs`
    if (request.from !== undefined && request.to !== undefined) {
      requestUrl = `${requestUrl}?from=${request.from}&to=${request.to}`
    }
    const response = await customFetchWithRetry(requestUrl)
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};

export const getOrgJobs = async (request: IOrgJobListRequest): Promise<IJobs | null> => {
  try {
    var requestUrl = `/org/${request.orgId}/jobs`
    if (request.from !== undefined && request.to !== undefined) {
      requestUrl = `${requestUrl}?from=${request.from}&to=${request.to}`
    }
    const response = await customFetchWithRetry(requestUrl)
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};

export const getJob = async (jobId: string): Promise<IJob | null> => {
  try {
    const response = await customFetchWithRetry(`/job/${jobId}`)
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};