import { IReportRequest, IReportResponse } from "../models";
import FetchError from "./errors";
import { customFetchWithRetry } from "./shared";

export const getReport = async (request: IReportRequest): Promise<IReportResponse | null> => {
  try {
    const response = await customFetchWithRetry(`/report/job`, {
      method: "POST",
      body: JSON.stringify(request)
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};