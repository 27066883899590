import FetchError from './errors';
import { customFetchWithRetry } from './shared';
import { ISurveyor } from 'src/models/Surveyor';

export const getSurveyors = async (orgId: string): Promise<ISurveyor[] | null> => {
  try {
    const response = await customFetchWithRetry(`/org/${orgId}/surveyor`, {
      method: 'GET'
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }

    const errorText = await response.text();
    throw new FetchError(`Error fetching surveyors. ${errorText}`, response.status);
  } catch (e: any) {
    throw new FetchError(e.message || e, 500);
  }
};
