import FetchError from "./errors";
import { IFinalizeMultipartUpload, IMultiPartPresignedUrlRequest, IMultiPartPresignedUrlResponse, IPresignedUrlRequest, IUploadImageRequest, IUploadJobImageRequest, IUploadRequest, IUploadRequestV2 } from "../models";
import { customFetchWithRetry, customUrlFetchWithRetry } from "./shared";
var Buffer = require("@craftzdog/react-native-buffer").Buffer;

const FILE_CHUNK_SIZE = 5242880;

export const getPresignedUrl = async (uploadRequest: IPresignedUrlRequest): Promise<string | null> => {
  try {
    const response = await customFetchWithRetry(`/upload`, {
      method: "POST",
      body: JSON.stringify(uploadRequest)
    }, true)
    if (response.ok) {
      return response.text();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    console.log('error fetching presigned url', e);
    throw new FetchError(e, 500);
  }
};

export const getMultiPartPresignedUrl = async (uploadRequest: IMultiPartPresignedUrlRequest): Promise<IMultiPartPresignedUrlResponse | null> => {
  try {
    const response = await customFetchWithRetry(`/uploadv2`, {
      method: "POST",
      body: JSON.stringify(uploadRequest)
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    console.log('error fetching presigned url', e);
    throw new FetchError(e, 500);
  }
};

export const finaliseMultiPartUpload = async (uploadRequest: IFinalizeMultipartUpload): Promise<boolean | null> => {
  try {

    console.log('finalize request', uploadRequest)
    const response = await customFetchWithRetry(`/uploadv2`, {
      method: "POST",
      body: JSON.stringify(uploadRequest)
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    console.log('error completing multi part upload', e);
    throw new FetchError(e, 500);
  }
};

export const uploadUsingPresignedUrl = async (request: IUploadRequest): Promise<boolean | null> => {
  try {
    var body = undefined
    var fileType = "image/jpeg"

    if (request.isBase64) {
      body = await Buffer.from(request.dataObject, "base64")
      fileType = body!!["type"]
    } else {
      const file = request.dataObject
      body = await getBlob(file.uri)
      fileType = body!!["type"]
    }

    const response = await customUrlFetchWithRetry(request.presignedUrl, {
      method: "PUT",
      body: body,
      headers: { "Content-Type": fileType }
    }, false)
    if (response.ok) {
      return true
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    console.log('error uploading object', e);
    throw new FetchError(e, 500);
  }
};

export const uploadUsingPresignedUrlV2 = async (request: IUploadRequestV2): Promise<string | null> => {
  try {
    var body = await getChunk(request)

    const response = await customUrlFetchWithRetry(request.presignedUrl, {
      method: "PUT",
      body: body
    }, false)

    if (response.ok) {
      return response.headers.get('ETag')
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    console.log('error uploading object', e);
    throw new FetchError(e, 500);
  }
};

export const uploadImage = async (request: IUploadImageRequest): Promise<boolean | null> => {
  const object_key = request.object_key;
  const numberofChunks = await getNumberOfParts(request)

  if (numberofChunks > 1) {
    // perform multi part upload
    const createMultiPartResp: IMultiPartPresignedUrlResponse | null = await getMultiPartPresignedUrl({
      object_key: object_key,
      action: "createMultiPart",
      numberOfParts: numberofChunks
    });

    if (createMultiPartResp == null) {
      throw Error("Error occurred while uploading")
    }

    let parts: any[] = []
    for (const [key, value] of Object.entries(createMultiPartResp.presignedUrls)) {
      const partNumber = Number(key) + 1;

      const uploadChunkResponse: string | null = await uploadUsingPresignedUrlV2({
        presignedUrl: value,
        dataObject: request.dataObject,
        isBase64: request.isBase64,
        totalParts: numberofChunks,
        partNumber: Number(key)
      });

      if (uploadChunkResponse == null) {
        throw Error("Error occurred while uploading part")
      }

      try {
        var eTag = uploadChunkResponse.replace(/"/g, "")
        parts.push({
          "ETag": eTag,
          "PartNumber": partNumber
        })
      } catch (e) {
        throw Error("Error occurred while uploading part")
      }
    }

    const finaliseMultipartResp = await finaliseMultiPartUpload({
      object_key: object_key,
      uploadId: createMultiPartResp.uploadId,
      parts: parts,
      action: "completeMultiPart"
    });
    return true
  } else {
    // perform single part upload
    const response = await getPresignedUrl({
      object_key: object_key,
      action: "putObject"
    });

    if (response == null) {
      throw Error("Error occurred while uploading")
    }

    const uploadResponse = await uploadUsingPresignedUrl({
      presignedUrl: response,
      isBase64: request.isBase64,
      dataObject: request.dataObject
    });

    if (uploadResponse == null) {
      throw Error("Error occurred while uploading")
    }
    return true
  }
}

const getBlob = async (fileUri: string) => {
  const resp = await fetch(fileUri);
  const imageBody = await resp.blob();
  return imageBody;
};

async function getChunk(request: IUploadRequestV2) {
  if (request.isBase64) {
    const start = request.partNumber * FILE_CHUNK_SIZE;
    const end = (request.partNumber + 1) * FILE_CHUNK_SIZE;
    var buffer = Buffer.from(request.dataObject, "base64")
    return buffer.subarray(start, end)
  } else {
    const file = request.dataObject
    var blob = await getBlob(file.uri)
    const start = request.partNumber * FILE_CHUNK_SIZE;
    const end = (request.partNumber + 1) * FILE_CHUNK_SIZE;

    const blobPart = request.partNumber < request.totalParts
      ? blob.slice(start, end)
      : blob.slice(start);
    return blobPart;
  }
}

async function getNumberOfParts(request: IUploadImageRequest) {
  if (request.isBase64) {
    var stringLength = request.dataObject.length - 'data:image/png;base64,'.length;
    var sizeInBytes = 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812;
    return Math.ceil(sizeInBytes / FILE_CHUNK_SIZE)
  } else {
    const file = request.dataObject
    const blob = await getBlob(file.uri)
    console.log('blob length', blob.size)
    return Math.ceil(blob.size / FILE_CHUNK_SIZE)
  }
}